.contents {
  width: 100vw;
  height: 100vh;
}

.hooks-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  border: 1px solid;
  background-color: black;
}

.hooks-element {
  width: 10rem;
}

.hooks-element svg {
  pointer-events: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.component-parent {
  width: 100vw;
  height: 20rem;
  position: relative;
  border: 1px solid;
}

.component-parent img {
  width: 5rem;
  height: auto;
}
